import React, { useState, useEffect } from 'react';
import { Card } from './ui/card';
import { HourlyTopicQuestion } from './HourlyTopicQuestion';

interface LiveArticleProps {
  id: string;
  title: string;
  content: string;
  timestamp: string;
  source: string;
  socialEngagement?: {
    shares: number;
    comments: number;
    reactions: number;
  };
  biasMetrics?: {
    politicalLean: number;
    emotionalTone: number;
    factOpinionRatio: number;
    sourceDiversity: number;
    loadedLanguage: number;
    missingContext: number;
    balancedViewpoints: Array<{
      perspective: string;
      coverage: number;
    }>;
    citationQuality: Array<any>;
  };
}

export const LiveArticle: React.FC<LiveArticleProps> = ({
  id,
  title,
  content,
  timestamp,
  source,
  socialEngagement,
  biasMetrics
}) => {
  const [hourlyQuestion, setHourlyQuestion] = useState<{
    text: string;
    context: string;
    timeRemaining: number;
    relatedTopics: string[];
  } | null>(null);

  useEffect(() => {
    // Fetch hourly question
    const fetchHourlyQuestion = async () => {
      try {
        const response = await fetch(`/api/articles/${id}/hourly-question`);
        const data = await response.json();
        setHourlyQuestion({
          text: data.question,
          context: data.context,
          timeRemaining: data.timeRemaining || 45,
          relatedTopics: data.relatedTopics || [],
        });
      } catch (error) {
        console.error('Error fetching hourly question:', error);
      }
    };

    fetchHourlyQuestion();
  }, [id]);

  const handleQuestionResponse = async (answer: string) => {
    try {
      await fetch(`/api/articles/${id}/hourly-question/respond`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ answer }),
      });
    } catch (error) {
      console.error('Error submitting response:', error);
    }
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <article className="prose lg:prose-xl">
          <header className="mb-6">
            <h1 className="text-3xl font-bold text-gray-900">{title}</h1>
            <div className="flex items-center gap-4 text-sm text-gray-500 mt-2">
              <time dateTime={timestamp}>
                {new Date(timestamp).toLocaleDateString()}
              </time>
              <span>Source: {source}</span>
              {socialEngagement && (
                <div className="flex gap-3">
                  <span>{socialEngagement.shares} shares</span>
                  <span>{socialEngagement.comments} comments</span>
                  <span>{socialEngagement.reactions} reactions</span>
                </div>
              )}
            </div>
          </header>

          <div className="text-gray-700">{content}</div>
        </article>
      </Card>

      {hourlyQuestion && (
        <HourlyTopicQuestion
          question={{
            id: id,
            text: hourlyQuestion.text,
            context: hourlyQuestion.context,
            timeRemaining: hourlyQuestion.timeRemaining,
            relatedTopics: hourlyQuestion.relatedTopics,
          }}
          onAnswer={handleQuestionResponse}
        />
      )}
    </div>
  );
};

// Add both named and default export
export default LiveArticle;
