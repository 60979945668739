import React, { useState } from 'react';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';
import * as auth from '../api/auth';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import BetaRequestForm from './BetaRequestForm';

interface LoginFormProps {
  onClose: () => void;
}

export default function LoginForm({ onClose }: LoginFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [showBetaForm, setShowBetaForm] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage(null);

    try {
      const response = await auth.login(email, password);
      localStorage.setItem('token', response.token);
      
      setMessage({ type: 'success', text: 'Successfully logged in!' });
      setTimeout(() => {
        onClose();
        navigate('/dashboard');
      }, 1500);
    } catch (error: any) {
      setMessage({ 
        type: 'error', 
        text: error.response?.data?.message || 'Invalid email or password.' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  // If beta form is shown, render that instead
  if (showBetaForm) {
    return <BetaRequestForm onClose={() => {
      setShowBetaForm(false);
      onClose();
    }} />;
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 px-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg max-w-md w-full mx-auto relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          ×
        </button>
        
        <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Login</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-4 py-3 rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 
              dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
              placeholder="Enter your email"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full px-4 py-3 rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 
                dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100 pr-10"
                placeholder="Enter your password"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                {showPassword ? <FiEyeOff size={20} /> : <FiEye size={20} />}
              </button>
            </div>
          </div>
          {message && (
            <p className={`text-sm ${
              message.type === 'error' ? 'text-red-600 dark:text-red-400' : 'text-green-600 dark:text-green-400'
            }`}>
              {message.text}
            </p>
          )}
          <div className="flex flex-col gap-4">
            <Button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gray-900 dark:bg-gray-100 text-gray-100 dark:text-gray-900"
            >
              {isLoading ? 'Please wait...' : 'Login'}
            </Button>

            <p className="text-center text-sm text-gray-600 dark:text-gray-400">
              Don't have an account?{' '}
              <button
                type="button"
                onClick={() => setShowBetaForm(true)}
                className="text-blue-600 dark:text-blue-400 hover:underline focus:outline-none"
              >
                Request Access
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
