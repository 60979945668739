import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { LiveArticle } from '../components/LiveArticle';
import TopicSummary from '../components/TopicSummary';
import EmotionalCheckIn from '../components/EmotionalCheckIn';
import Clock from '../components/Clock';

interface ArticleData {
  id: string;
  title: string;
  content: string;
  timestamp: string;
  source: string;
  socialEngagement?: {
    shares: number;
    comments: number;
    reactions: number;
  };
  biasMetrics?: {
    politicalLean: number;
    emotionalTone: number;
    factOpinionRatio: number;
    sourceDiversity: number;
    loadedLanguage: number;
    missingContext: number;
    balancedViewpoints: Array<{
      perspective: string;
      coverage: number;
    }>;
    citationQuality: Array<any>;
  };
}

interface TopicData {
  title: string;
  summary: {
    who: string[];
    what: string;
    where: string[];
    when: string;
    why: string;
    how: string;
  };
  perspectives: {
    type: string;
    count: number;
    summary: string;
  }[];
  sentiment: {
    positive: number;
    neutral: number;
    negative: number;
  };
}

const loadingMessages = [
  "Analyzing perspectives...",
  "Gathering diverse viewpoints...",
  "Mapping opinion clusters...",
  "Detecting sentiment patterns...",
  "Evaluating source credibility..."
];

const LiveArticlePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isAuthenticated } = useAuth();
  const [article, setArticle] = useState<ArticleData | null>(null);
  const [topicData, setTopicData] = useState<TopicData | null>(null);
  const [membershipLevel] = useState<'basic' | 'premium'>('basic');
  const [hasCheckedIn, setHasCheckedIn] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingMessageIndex((prev) => (prev + 1) % loadingMessages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // In production, replace with actual API call
        const response = await fetch(`/api/articles/${id}`);
        const data = await response.json();
        setArticle(data);

        const responseTopic = await fetch(`/api/articles/${id}/topic`);
        const dataTopic = await responseTopic.json();
        setTopicData(dataTopic);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    if (id) {
      fetchArticle();
    }
  }, [id]);

  const handleEmotionalCheckIn = async (response: { feeling: string; thoughts: string }) => {
    // In production, send this data to your API
    console.log('Emotional check-in:', response);
    setHasCheckedIn(true);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        {isAuthenticated && !hasCheckedIn && (
          <EmotionalCheckIn onComplete={handleEmotionalCheckIn} />
        )}
        {(!isAuthenticated || hasCheckedIn) && !article && (
          <div className="max-w-4xl mx-auto">
            <div className="text-center space-y-8">
              <div className="flex justify-between items-center mb-6">
                <h1 className="font-serif text-4xl font-bold text-gray-900 border-b-2 border-gray-200 pb-4">
                  THE OVERSTANDER
                </h1>
                <Clock />
              </div>
              <div className="text-sm font-serif text-gray-500">
                {new Date().toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </div>
              
              <div className="my-12">
                <h2 className="text-2xl font-serif font-bold text-gray-800 mb-6">
                  Gathering Perspectives...
                </h2>
                <div className="relative h-24 overflow-hidden">
                  {loadingMessages.map((message, index) => (
                    <p
                      key={message}
                      className={`absolute w-full transition-all duration-500 ${
                        index === loadingMessageIndex 
                          ? 'opacity-100 transform translate-y-0' 
                          : 'opacity-0 transform translate-y-8'
                      }`}
                    >
                      <span className="inline-block font-serif text-gray-600">{message}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {(!isAuthenticated || hasCheckedIn) && article && (
          <div className="max-w-4xl mx-auto space-y-8">
            <header className="text-center space-y-4 mb-12">
              <div className="flex justify-between items-center mb-6">
                <h1 className="font-serif text-4xl font-bold text-gray-900">THE OVERSTANDER</h1>
                <Clock />
              </div>
              <div className="text-sm font-serif text-gray-500 border-t border-b border-gray-200 py-2">
                {new Date().toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </div>
            </header>

            <LiveArticle {...article} />
            
            {topicData && (
              <TopicSummary
                topic={topicData}
                membershipLevel={membershipLevel}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveArticlePage;
