import React, { FC } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LiveArticlePage from './pages/LiveArticlePage';
import LandingPage from './pages/LandingPage';
import { UpdateBanner } from './components/UpdateBanner';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import SignUpPage from './pages/SignUpPage';
import AdminPage from './pages/AdminPage';
import { ProtectedRoute } from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';
import { ComingSoonProtection } from './components/ComingSoonProtection';
import { queryClient } from './utils/queryClient';
import './styles/globals.css';

const App: FC = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AuthProvider>
            <Router>
              <ComingSoonProtection>
                <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
                  <UpdateBanner />
                  <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route 
                      path="/dashboard" 
                      element={
                        <ProtectedRoute>
                          <LiveArticlePage />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/article/:id" 
                      element={
                        <ProtectedRoute>
                          <LiveArticlePage />
                        </ProtectedRoute>
                      } 
                    />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route 
                      path="/admin" 
                      element={
                        <ProtectedRoute requireAdmin>
                          <AdminPage />
                        </ProtectedRoute>
                      } 
                    />
                  </Routes>
                </div>
              </ComingSoonProtection>
            </Router>
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
