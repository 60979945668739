import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';

interface ThemeToggleProps {
  className?: string;
}

export const ThemeToggle: React.FC<ThemeToggleProps> = ({ className }) => {
  const { theme, toggleTheme } = useTheme();

  return (
    <motion.button
      onClick={toggleTheme}
      className={`fixed top-16 right-4 z-50 p-2 rounded-full bg-gray-100 dark:bg-gray-800 
                 shadow-lg hover:shadow-xl transition-shadow ${className || ''}`}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="w-6 h-6 flex items-center justify-center text-lg">
        {theme === 'dark' ? (
          <span role="img" aria-label="Light mode">
            ☀️
          </span>
        ) : (
          <span role="img" aria-label="Dark mode">
            🌙
          </span>
        )}
      </div>
    </motion.button>
  );
};
