import React from 'react';
import { Card } from './ui/card';

interface TopicSummaryProps {
  topic: {
    title: string;
    summary: {
      who: string[];
      what: string;
      where: string[];
      when: string;
      why: string;
      how: string;
    };
    perspectives: {
      type: string;
      count: number;
      summary: string;
    }[];
    sentiment: {
      positive: number;
      neutral: number;
      negative: number;
    };
  };
  membershipLevel: 'basic' | 'premium';
}

export const TopicSummary: React.FC<TopicSummaryProps> = ({ topic, membershipLevel }) => {
  const isPremium = membershipLevel === 'premium';

  return (
    <Card className="max-w-4xl mx-auto p-6 space-y-8">
      <div className="space-y-4">
        <h2 className="text-3xl font-bold text-gray-900">{topic.title}</h2>
        
        {/* 5W1H Summary */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-800">Who</h3>
            <ul className="list-disc list-inside text-gray-600">
              {topic.summary.who.map((person, index) => (
                <li key={index}>{person}</li>
              ))}
            </ul>
          </div>
          
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-800">What</h3>
            <p className="text-gray-600">{topic.summary.what}</p>
          </div>
          
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-800">Where</h3>
            <ul className="list-disc list-inside text-gray-600">
              {topic.summary.where.map((location, index) => (
                <li key={index}>{location}</li>
              ))}
            </ul>
          </div>
          
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-800">When</h3>
            <p className="text-gray-600">{topic.summary.when}</p>
          </div>
          
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-800">Why</h3>
            <p className="text-gray-600">{topic.summary.why}</p>
          </div>
          
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-800">How</h3>
            <p className="text-gray-600">{topic.summary.how}</p>
          </div>
        </div>

        {/* Perspective Analysis */}
        <div className="mt-8">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Perspective Analysis</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {topic.perspectives.map((perspective, index) => (
              <div
                key={index}
                className="p-4 rounded-lg bg-gray-50 space-y-2"
              >
                <div className="flex justify-between items-center">
                  <span className="font-medium text-gray-700">{perspective.type}</span>
                  <span className="text-sm text-gray-500">{perspective.count} responses</span>
                </div>
                <p className="text-sm text-gray-600">{perspective.summary}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Sentiment Overview */}
        <div className="mt-8">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Overall Sentiment</h3>
          <div className="flex gap-4">
            <div className="flex-1 bg-green-100 p-4 rounded-lg">
              <div className="text-green-800 font-medium">Positive</div>
              <div className="text-2xl font-bold text-green-900">
                {Math.round(topic.sentiment.positive * 100)}%
              </div>
            </div>
            <div className="flex-1 bg-gray-100 p-4 rounded-lg">
              <div className="text-gray-800 font-medium">Neutral</div>
              <div className="text-2xl font-bold text-gray-900">
                {Math.round(topic.sentiment.neutral * 100)}%
              </div>
            </div>
            <div className="flex-1 bg-red-100 p-4 rounded-lg">
              <div className="text-red-800 font-medium">Negative</div>
              <div className="text-2xl font-bold text-red-900">
                {Math.round(topic.sentiment.negative * 100)}%
              </div>
            </div>
          </div>
        </div>

        {/* Premium Features Banner */}
        {!isPremium && (
          <div className="mt-8 p-4 bg-blue-50 rounded-lg border border-blue-200">
            <div className="flex items-center justify-between">
              <div>
                <h4 className="text-lg font-semibold text-blue-900">Unlock Real-Time Updates</h4>
                <p className="text-sm text-blue-700">
                  Get instant access to perspective changes and deeper analysis with Premium
                </p>
              </div>
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                Upgrade Now
              </button>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default TopicSummary;
