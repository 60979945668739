import React, { useState } from 'react';
import { Button } from './ui/button';
import * as betaApi from '../api/betaAccess';
import { FiX } from 'react-icons/fi';

interface BetaRequestFormProps {
  onClose: () => void;
}

export default function BetaRequestForm({ onClose }: BetaRequestFormProps) {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await betaApi.requestAccess(email);
      setSubmitted(true);
      setTimeout(() => {
        onClose();
        setSubmitted(false);
        setEmail('');
      }, 3000);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to submit request. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 px-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg max-w-md w-full mx-auto relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <FiX size={24} />
        </button>
        
        {!submitted ? (
          <>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Join the Beta</h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Be among the first to experience unbiased, multi-perspective news analysis. 
              We'll notify you as soon as your access is granted.
            </p>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full px-4 py-3 rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 
                  dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {error && (
                <p className="text-sm text-red-600 dark:text-red-400">
                  {error}
                </p>
              )}

              <Button
                type="submit"
                disabled={isLoading}
                className="w-full bg-blue-600 text-white dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-600"
              >
                {isLoading ? 'Submitting...' : 'Request Access'}
              </Button>
            </form>
          </>
        ) : (
          <div className="text-center py-8">
            <div className="text-5xl mb-4">✨</div>
            <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-2">
              Thank you for your interest!
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              We've received your request and will notify you at <strong>{email}</strong> when your access is granted.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
