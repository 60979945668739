import React, { useState } from 'react';
import { Card } from './ui/card';

interface HourlyTopicQuestionProps {
  question: {
    id: string;
    text: string;
    context: string;
    timeRemaining: number; // in minutes
    relatedTopics: string[];
  };
  onAnswer: (answer: string) => void;
}

export const HourlyTopicQuestion: React.FC<HourlyTopicQuestionProps> = ({
  question,
  onAnswer,
}) => {
  const [answer, setAnswer] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formatTimeRemaining = (minutes: number) => {
    if (minutes < 1) return 'Less than a minute';
    if (minutes === 1) return '1 minute';
    if (minutes < 60) return `${minutes} minutes`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!answer.trim()) return;

    setIsSubmitting(true);
    try {
      await onAnswer(answer);
      setAnswer('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="max-w-2xl mx-auto p-6 bg-white/90 backdrop-blur">
      <div className="space-y-6">
        <div className="flex justify-between items-start">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">Hourly Question</h2>
            <p className="text-sm text-gray-500">Shape the collective understanding</p>
          </div>
          <div className="text-right">
            <div className="text-sm font-medium text-blue-600">
              Next question in
            </div>
            <div className="text-gray-900">
              {formatTimeRemaining(question.timeRemaining)}
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900">{question.text}</h3>
            <p className="text-sm text-gray-600 mt-1">{question.context}</p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <textarea
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              rows={4}
              placeholder="Share your perspective..."
              disabled={isSubmitting}
            />

            <div className="flex items-center justify-between">
              <div className="flex flex-wrap gap-2">
                {question.relatedTopics.map((topic, index) => (
                  <span
                    key={index}
                    className="px-2 py-1 text-xs bg-gray-100 text-gray-600 rounded-full"
                  >
                    {topic}
                  </span>
                ))}
              </div>

              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                disabled={isSubmitting || !answer.trim()}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>

          <div className="text-xs text-gray-400 text-center">
            Your anonymous response contributes to a deeper understanding of this topic
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HourlyTopicQuestion;
