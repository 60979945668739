import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { useTheme } from '../context/ThemeContext';
import { ThemeToggle } from '../components/ThemeToggle';
import LoginForm from '../components/LoginForm';
import BetaRequestForm from '../components/BetaRequestForm';
import { FiGlobe, FiTrendingUp, FiUsers, FiDatabase, FiRadio, FiChevronRight, FiAward } from 'react-icons/fi';
import { HiOutlineLightBulb, HiOutlineScale } from 'react-icons/hi';
import { AiOutlineApi } from 'react-icons/ai';
import { FiCheck } from 'react-icons/fi';
import clsx from 'clsx';

interface BadgeProps {
  type: 'coming-soon' | 'beta';
  className?: string;
}

const Badge = ({ type, className }: BadgeProps) => {
  return (
    <span className={clsx(
      "px-2 py-1 text-xs font-semibold rounded",
      type === 'coming-soon' ? 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300' : 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
      className
    )}>
      {type === 'coming-soon' ? 'COMING SOON' : 'BETA'}
    </span>
  );
};

export default function LandingPage() {
  const { theme } = useTheme();
  const [showBetaForm, setShowBetaForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 relative font-serif">
      {/* Noise overlay */}
      <div 
        className="fixed inset-0 pointer-events-none opacity-[0.02]" 
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
          mixBlendMode: theme === 'dark' ? 'lighten' : 'darken',
        }}
      />
      
      <ThemeToggle className="fixed top-4 right-4 p-2 text-gray-900 dark:text-gray-100" />
      
      {/* Login Link */}
      <div className="fixed top-4 right-16 p-2 z-50">
        <button 
          onClick={() => setShowLoginForm(true)}
          className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 cursor-pointer"
        >
          Login
        </button>
      </div>

      {/* Hero Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto text-center">
          <div className="border-b-2 border-gray-900 dark:border-gray-100 pb-8 mb-8">
            <h1 className="text-5xl md:text-7xl font-serif font-bold text-gray-900 dark:text-gray-100 tracking-tight leading-none mb-4">
              THE OVERSTANDER
            </h1>
            <div className="text-sm text-gray-600 dark:text-gray-400 space-y-2">
              <p className="uppercase tracking-widest">
                Unbiased • Multi-Perspective • Global Truth
              </p>
              <p className="font-mono">
                {formatDate(currentTime)}
              </p>
            </div>
          </div>
          
          <p className="text-2xl md:text-3xl text-gray-900 dark:text-gray-100 font-serif leading-relaxed mb-8">
            Empowering the world with AI-driven global insights and unbiased perspectives.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12 text-left">
            <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg">
              <div className="text-3xl font-bold text-blue-600 dark:text-blue-400 mb-2">500M+</div>
              <div className="text-base text-gray-600 dark:text-gray-400">Global opinions analyzed daily</div>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg">
              <div className="text-3xl font-bold text-blue-600 dark:text-blue-400 mb-2">98%</div>
              <div className="text-base text-gray-600 dark:text-gray-400">Bias detection accuracy</div>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg">
              <div className="text-3xl font-bold text-blue-600 dark:text-blue-400 mb-2">Real-Time</div>
              <div className="text-base text-gray-600 dark:text-gray-400">Global sentiment tracking</div>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
          </div>

          <Button 
            onClick={() => setShowBetaForm(true)}
            className="bg-blue-600 text-white dark:bg-blue-500 px-8 py-4 text-lg rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors mb-24"
          >
            Join the Movement <FiChevronRight className="inline ml-2" />
          </Button>
        </div>

        {/* Core Features */}
        <div className="max-w-6xl mx-auto mb-24">
          <h2 className="text-3xl font-bold text-center mb-4 text-gray-900 dark:text-gray-100">Why Overstander?</h2>
          <p className="text-center text-lg text-gray-600 dark:text-gray-400 mb-12 max-w-2xl mx-auto leading-relaxed">
            We're building a future where truth is accessible, verifiable, and free from manipulation.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <HiOutlineScale className="text-4xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Unbiased Analysis</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">Advanced AI algorithms detect and neutralize bias across sources, ensuring balanced perspectives.</p>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <FiGlobe className="text-4xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Global Perspective</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">Real-time analysis of news, social media, and public sentiment from every corner of the world.</p>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <HiOutlineLightBulb className="text-4xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Truth Discovery</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">Multi-perspective analysis reveals deeper insights and factual consensus across diverse sources.</p>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
          </div>
        </div>

        {/* Real-World Applications */}
        <div className="max-w-6xl mx-auto mb-24 bg-gray-50 dark:bg-gray-800 rounded-2xl p-12">
          <h2 className="text-3xl font-bold text-center mb-4 text-gray-900 dark:text-gray-100">Real-World Impact</h2>
          <p className="text-center text-lg text-gray-600 dark:text-gray-400 mb-12 max-w-2xl mx-auto leading-relaxed">
            From global events to local stories, Overstander provides unprecedented insights.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <FiTrendingUp className="text-3xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Global Opinion Polling</h3>
              <ul className="space-y-4 text-lg text-gray-600 dark:text-gray-400">
                <li className="flex items-start">
                  <FiChevronRight className="mt-1.5 mr-2 text-blue-600 flex-shrink-0" />
                  <span>Real-time sentiment analysis across 150+ countries</span>
                </li>
                <li className="flex items-start">
                  <FiChevronRight className="mt-1.5 mr-2 text-blue-600 flex-shrink-0" />
                  <span>Demographic and geographic sentiment breakdowns</span>
                </li>
                <li className="flex items-start">
                  <FiChevronRight className="mt-1.5 mr-2 text-blue-600 flex-shrink-0" />
                  <span>Trend analysis and prediction modeling</span>
                </li>
              </ul>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div>
              <FiDatabase className="text-3xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Data & Broadcasting</h3>
              <ul className="space-y-4 text-lg text-gray-600 dark:text-gray-400">
                <li className="flex items-start">
                  <FiChevronRight className="mt-1.5 mr-2 text-blue-600 flex-shrink-0" />
                  <span>Rich API for real-time data access and integration</span>
                </li>
                <li className="flex items-start">
                  <FiChevronRight className="mt-1.5 mr-2 text-blue-600 flex-shrink-0" />
                  <span>Broadcast-ready insights and visualizations</span>
                </li>
                <li className="flex items-start">
                  <FiChevronRight className="mt-1.5 mr-2 text-blue-600 flex-shrink-0" />
                  <span>Custom datasets and analytics</span>
                </li>
              </ul>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="max-w-6xl mx-auto mb-24">
          <h2 className="text-3xl font-bold text-center mb-4 text-gray-900 dark:text-gray-100">Key Features</h2>
          <p className="text-center text-lg text-gray-600 dark:text-gray-400 mb-12 max-w-2xl mx-auto leading-relaxed">
            Powerful tools for understanding the world's perspectives
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <FiUsers className="text-3xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Topic Following</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">Track evolving narratives and sentiment changes across your chosen topics in real-time.</p>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <FiAward className="text-3xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Auto Summary</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">AI-powered summaries distill complex topics into clear, actionable insights.</p>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <AiOutlineApi className="text-3xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Developer API</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">Integrate global insights into your applications with our comprehensive API.</p>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <FiRadio className="text-3xl text-blue-600 mb-4" />
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Broadcast Ready</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">Export professional visualizations and insights ready for broadcast media.</p>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
          </div>
        </div>

        {/* Pricing Section */}
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-4 text-gray-900 dark:text-gray-100">Choose Your Plan</h2>
          <p className="text-center text-lg text-gray-600 dark:text-gray-400 mb-12 max-w-2xl mx-auto">
            Start with our free plan to experience the power of multi-perspective news analysis, or upgrade to Pro for advanced features and deeper insights.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Free Tier */}
            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-2 text-gray-900 dark:text-gray-100">Free</h3>
              <p className="text-lg text-gray-600 dark:text-gray-400 mb-6">Essential tools for informed reading</p>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-600 dark:text-gray-400">
                  <span className="mr-2">✓</span> 5 daily news summaries
                </li>
                <li className="flex items-center text-gray-600 dark:text-gray-400">
                  <span className="mr-2">✓</span> Basic sentiment analysis
                </li>
                <li className="flex items-center text-gray-600 dark:text-gray-400">
                  <span className="mr-2">✓</span> 3 article deep-dives per day
                </li>
                <li className="flex items-center text-gray-600 dark:text-gray-400">
                  <span className="mr-2">✓</span> Community discussions
                </li>
              </ul>
              <Button 
                onClick={() => setShowBetaForm(true)}
                className="w-full bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-600"
              >
                Start Free
              </Button>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>

            {/* Pro Tier */}
            <div className="relative flex flex-col p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              {/* Coming Soon Overlay */}
              <div className="absolute inset-0 bg-gray-900/50 dark:bg-gray-900/70 rounded-lg flex items-center justify-center">
                <span className="text-white font-bold text-xl">Coming Soon</span>
              </div>
              <h3 className="text-xl font-bold mb-4">Pro Tier</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-3xl font-bold">$99</span>
                <span className="text-base font-medium text-gray-500 dark:text-gray-400 ml-1">/month</span>
              </div>
              <ul className="mb-6 space-y-2">
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Everything in Free tier
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Unlimited news summaries
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Advanced sentiment analysis with historical trends
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Unlimited article deep-dives
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Custom topic tracking & alerts
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  API access with 100k requests/month
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Export data in multiple formats
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Priority support with 4-hour response
                </li>
                <li className="flex items-center">
                  <FiCheck className="mr-2 text-green-500" />
                  Early access to new features
                </li>
              </ul>
              <Button
                className="w-full opacity-50 cursor-not-allowed"
                disabled={true}
              >
                Get Pro Access
              </Button>
              <Badge type="beta" className="absolute top-0 right-0" />
            </div>
          </div>
        </div>
      </div>

      {showLoginForm && (
        <LoginForm onClose={() => setShowLoginForm(false)} />
      )}

      {showBetaForm && (
        <BetaRequestForm onClose={() => setShowBetaForm(false)} />
      )}
    </div>
  );
}
