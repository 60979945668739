import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import * as betaApi from '../api/betaAccess';
import { isAdmin } from '../api/auth';
import { useNavigate } from 'react-router-dom';

interface BetaRequest {
  id: string;
  email: string;
  requestedAt: string;
  status: 'pending' | 'approved' | 'rejected';
}

export default function AdminPage() {
  const [requests, setRequests] = useState<BetaRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is admin, if not redirect to home
    if (!isAdmin()) {
      navigate('/');
      return;
    }

    fetchRequests();
  }, [navigate]);

  const fetchRequests = async () => {
    try {
      const data = await betaApi.getAllRequests();
      setRequests(data);
    } catch (err) {
      setError('Failed to fetch beta access requests');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (requestId: string, status: 'approved' | 'rejected') => {
    try {
      await betaApi.updateRequestStatus(requestId, status);
      setRequests(prev => 
        prev.map(req => req.id === requestId ? { ...req, status } : req)
      );
    } catch (err) {
      setError('Failed to update request status');
    }
  };

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Beta Access Requests</h1>
      <div className="space-y-4">
        {requests.map((request) => (
          <div key={request.id} className="p-4 border rounded-lg flex items-center justify-between">
            <div>
              <p className="font-medium">{request.email}</p>
              <p className="text-sm text-gray-500">Status: {request.status}</p>
              <p className="text-sm text-gray-500">Requested: {new Date(request.requestedAt).toLocaleDateString()}</p>
            </div>
            {request.status === 'pending' && (
              <div className="space-x-2">
                <Button 
                  onClick={() => handleUpdateStatus(request.id, 'approved')}
                  className="bg-green-500 hover:bg-green-600"
                >
                  Approve
                </Button>
                <Button 
                  onClick={() => handleUpdateStatus(request.id, 'rejected')}
                  className="bg-red-500 hover:bg-red-600"
                >
                  Reject
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
