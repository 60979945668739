import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface LiveMetrics {
  activeDiscussions: number;
  perspectivesShared: number;
  mindsChanged: number;
  userGrowthScore: number;
}

interface Feature {
  title: string;
  description: string;
  icon: string;
  stats?: string;
}

export const SignUpPage: React.FC = () => {
  const [metrics, setMetrics] = useState<LiveMetrics>({
    activeDiscussions: 0,
    perspectivesShared: 0,
    mindsChanged: 0,
    userGrowthScore: 0,
  });

  const features: Feature[] = [
    {
      title: 'Source Explorer',
      description: 'Discover and contribute high-quality sources. Earn recognition for bringing valuable context to discussions.',
      icon: '🔍',
      stats: 'Top contributors have shared 500+ verified sources'
    },
    {
      title: 'AI-Enhanced Understanding',
      description: 'Our AI helps connect ideas and uncover insights you might have missed in complex topics.',
      icon: '🧠',
      stats: '84% of users discover new angles on familiar topics'
    },
    {
      title: 'Achievement System',
      description: 'Unlock achievements for exploring topics deeply, finding connections between ideas, and contributing valuable sources.',
      icon: '🏆',
      stats: 'Average user explores 12+ perspectives per topic'
    },
    {
      title: 'Topic Mastery',
      description: 'Track your depth of understanding across different topics. Earn recognition for comprehensive knowledge.',
      icon: '📚',
      stats: 'Users typically master 3+ topics per month'
    }
  ];

  useEffect(() => {
    // Simulate live metrics updates
    const interval = setInterval(() => {
      setMetrics(prev => ({
        activeDiscussions: prev.activeDiscussions + Math.floor(Math.random() * 3),
        perspectivesShared: prev.perspectivesShared + Math.floor(Math.random() * 5),
        mindsChanged: prev.mindsChanged + Math.floor(Math.random() * 2),
        userGrowthScore: Math.min(100, prev.userGrowthScore + Math.random()),
      }));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto space-y-12">
          {/* Header */}
          <motion.div 
            className="text-center space-y-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="font-serif text-5xl font-bold text-gray-900">
              Expand Your Understanding
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Join thousands of others seeking to understand different perspectives and grow together.
            </p>
          </motion.div>

          {/* Live Metrics */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <AnimatePresence>
              <motion.div
                key="active-discussions"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
                className="bg-white p-6 rounded-lg shadow-sm text-center"
              >
                <div className="text-3xl font-bold text-gray-900">{metrics.activeDiscussions}</div>
                <div className="text-sm text-gray-600">Active Discussions</div>
              </motion.div>
              <motion.div
                key="perspectives-shared"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
                className="bg-white p-6 rounded-lg shadow-sm text-center"
              >
                <div className="text-3xl font-bold text-gray-900">{metrics.perspectivesShared}</div>
                <div className="text-sm text-gray-600">Perspectives Shared Today</div>
              </motion.div>
              <motion.div
                key="minds-changed"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                className="bg-white p-6 rounded-lg shadow-sm text-center"
              >
                <div className="text-3xl font-bold text-gray-900">{metrics.mindsChanged}</div>
                <div className="text-sm text-gray-600">New Insights Gained</div>
              </motion.div>
              <motion.div
                key="growth-score"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                className="bg-white p-6 rounded-lg shadow-sm text-center"
              >
                <div className="text-3xl font-bold text-gray-900">
                  {Math.round(metrics.userGrowthScore)}%
                </div>
                <div className="text-sm text-gray-600">Community Growth</div>
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Features */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-sm space-y-4"
              >
                <div className="flex items-center space-x-4">
                  <span className="text-4xl">{feature.icon}</span>
                  <h3 className="text-xl font-bold text-gray-900">{feature.title}</h3>
                </div>
                <p className="text-gray-600">{feature.description}</p>
                {feature.stats && (
                  <p className="text-sm text-gray-500 italic">{feature.stats}</p>
                )}
              </motion.div>
            ))}
          </div>

          {/* Sign Up Form */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="bg-white p-8 rounded-lg shadow-sm space-y-6"
          >
            <h2 className="text-2xl font-bold text-gray-900 text-center">
              Join the Discussion
            </h2>
            <div className="space-y-4">
              <input
                type="email"
                placeholder="Email"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
              />
              <input
                type="password"
                placeholder="Password"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
              />
              <button className="w-full bg-gray-900 text-white py-3 rounded-lg hover:bg-gray-800 transition-colors">
                Sign Up
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
