import React, { useState } from 'react';
import { Card } from './ui/card';

interface EmotionalCheckInProps {
  onComplete: (response: { feeling: string; thoughts: string }) => void;
}

interface EmotionOption {
  label: string;
  emoji: string;
  followUpQuestion: string;
  placeholder: string;
}

const emotions: EmotionOption[] = [
  {
    label: 'Optimistic',
    emoji: '😊',
    followUpQuestion: "What's giving you hope today?",
    placeholder: "Share what's making you feel positive..."
  },
  {
    label: 'Neutral',
    emoji: '😐',
    followUpQuestion: "What's on your radar today?",
    placeholder: "Tell us what you're thinking about..."
  },
  {
    label: 'Concerned',
    emoji: '😟',
    followUpQuestion: "What's weighing on your mind?",
    placeholder: "Share your concerns, we're here to understand..."
  },
  {
    label: 'Curious',
    emoji: '🧐',
    followUpQuestion: "What are you eager to learn more about?",
    placeholder: "Tell us what sparks your curiosity..."
  },
  {
    label: 'Frustrated',
    emoji: '😤',
    followUpQuestion: "What challenges are you facing?",
    placeholder: "Share what's frustrating you, let's explore different perspectives..."
  },
  {
    label: 'Hopeful',
    emoji: '🌅',
    followUpQuestion: "What positive changes do you see coming?",
    placeholder: "Tell us about the future you envision..."
  }
];

export const EmotionalCheckIn: React.FC<EmotionalCheckInProps> = ({ onComplete }) => {
  const [selectedEmotion, setSelectedEmotion] = useState<EmotionOption | null>(null);
  const [thoughts, setThoughts] = useState('');
  const [step, setStep] = useState(0);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedEmotion && thoughts) {
      onComplete({ feeling: selectedEmotion.label, thoughts });
    }
  };

  return (
    <Card className="max-w-2xl mx-auto p-8 bg-white/90 backdrop-blur shadow-lg">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <h2 className="text-2xl font-bold text-gray-900">Before we begin...</h2>
          <p className="text-gray-600">
            Help us understand your current state of mind to provide more relevant perspectives.
          </p>

          <div className="space-y-4">
            {step === 0 ? (
              <div className="space-y-3 animate-fadeIn">
                <label className="block text-lg font-medium text-gray-700">
                  How are you feeling right now?
                </label>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                  {emotions.map((emotion) => (
                    <button
                      key={emotion.label}
                      type="button"
                      onClick={() => {
                        setSelectedEmotion(emotion);
                        setStep(1);
                      }}
                      className={`p-4 rounded-lg border transition-all flex items-center justify-center space-x-2 ${
                        selectedEmotion?.label === emotion.label
                          ? 'border-primary bg-primary/10 text-primary'
                          : 'border-gray-200 hover:border-primary/50'
                      }`}
                    >
                      <span className="text-xl">{emotion.emoji}</span>
                      <span>{emotion.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="space-y-3 animate-fadeIn">
                <div className="flex items-center space-x-2 text-lg font-medium text-gray-700">
                  <span className="text-2xl">{selectedEmotion?.emoji}</span>
                  <label className="block">{selectedEmotion?.followUpQuestion}</label>
                </div>
                <textarea
                  value={thoughts}
                  onChange={(e) => setThoughts(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent min-h-[120px]"
                  placeholder={selectedEmotion?.placeholder}
                />
                <div className="flex justify-between items-center">
                  <button
                    type="button"
                    onClick={() => {
                      setStep(0);
                      setThoughts('');
                    }}
                    className="text-sm text-gray-500 hover:text-gray-700 flex items-center space-x-1"
                  >
                    <span>←</span>
                    <span>Different emotion</span>
                  </button>
                  <button
                    type="submit"
                    disabled={!thoughts.trim()}
                    className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
                  >
                    <span>Continue</span>
                    <span>→</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </Card>
  );
};

export default EmotionalCheckIn;
