import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export interface BetaRequest {
  id: string;
  email: string;
  requestedAt: string;
  status: 'pending' | 'approved' | 'rejected';
}

// Request beta access
export const requestAccess = async (email: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/beta-access/request`, { email });
    return response.data;
  } catch (error) {
    console.error('Error requesting beta access:', error);
    throw error;
  }
};

// Get all beta requests (admin only)
export const getAllRequests = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/beta-access/requests`);
    return response.data as BetaRequest[];
  } catch (error) {
    console.error('Error fetching beta requests:', error);
    throw error;
  }
};

// Update request status (admin only)
export const updateRequestStatus = async (requestId: string, status: 'approved' | 'rejected') => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/beta-access/requests/${requestId}`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating request status:', error);
    throw error;
  }
};
