import React, { useEffect, useState } from 'react';

export const UpdateBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Listen for webpack hot updates
    const hotUpdateHandler = (event: MessageEvent) => {
      if (event.data && event.data.type === 'webpackHotUpdate') {
        setShowBanner(true);
      }
    };

    window.addEventListener('message', hotUpdateHandler);

    return () => {
      window.removeEventListener('message', hotUpdateHandler);
    };
  }, []);

  if (!showBanner) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bg-primary z-50 p-4 shadow-lg">
      <div className="max-w-7xl mx-auto flex items-center justify-between text-primary-foreground">
        <div className="flex items-center space-x-2">
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>A new version of the site is available!</span>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => window.location.reload()}
            className="bg-primary-foreground text-primary px-4 py-1 rounded-md hover:bg-primary-foreground/90 transition-colors"
          >
            Refresh Now
          </button>
          <button
            onClick={() => setShowBanner(false)}
            className="text-primary-foreground/80 hover:text-primary-foreground"
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  );
};
