import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

// Add auth header to all requests if token exists
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export interface AuthResponse {
  token: string;
  user: {
    id: string;
    email: string;
    role?: 'admin' | 'user';
  };
}

export const login = async (email: string, password: string): Promise<AuthResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/auth/login`, { email, password });
    // Store the user role in localStorage if it exists
    if (response.data.user.role) {
      localStorage.setItem('userRole', response.data.user.role);
    }
    localStorage.setItem('token', response.data.token);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const verifyToken = async (token: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/auth/verify`, { token });
    return response.data.user;
  } catch (error) {
    console.error('Error verifying token:', error);
    throw error;
  }
};

export const isAdmin = async (): Promise<boolean> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/auth/check-admin`);
    return response.data.isAdmin;
  } catch (error) {
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userRole');
  window.location.href = '/';
};
